<template>
    <svg style="display: none;">
        <symbol id="fa-gauge-max" viewBox="0 0 512 512"><path fill="currentColor" d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM256 416c35.3 0 64-28.7 64-64c0-3.7-.3-7.3-.9-10.8l117.5-72.8c11.3-7 14.7-21.8 7.8-33s-21.8-14.8-33-7.8L293.8 300.4C283.2 292.6 270.1 288 256 288c-35.3 0-64 28.7-64 64s28.7 64 64 64zM176 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM96 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM400 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></symbol>
        <symbol id="fa-conveyor-belt-boxes" viewBox="0 0 640 512"><path fill="currentColor" d="M96 256h192c17.6 0 32-14.4 32-32V32c0-17.6-14.4-32-32-32H96C78.4 0 64 14.4 64 32v192C64 241.6 78.4 256 96 256zM416 256h128c17.6 0 32-14.4 32-32V96c0-17.6-14.4-32-32-32h-128c-17.6 0-32 14.4-32 32v128C384 241.6 398.4 256 416 256zM544 320H96c-53 0-96 43-96 96s43 96 96 96h448c53 0 96-43 96-96S597 320 544 320zM128 448c-17.75 0-32-14.25-32-32s14.25-32 32-32s32 14.25 32 32S145.8 448 128 448zM320 448c-17.75 0-32-14.25-32-32s14.25-32 32-32s32 14.25 32 32S337.8 448 320 448zM512 448c-17.75 0-32-14.25-32-32s14.25-32 32-32c17.75 0 32 14.25 32 32S529.8 448 512 448z"/></symbol>
        <symbol id="fa-envelope-open-dollar" viewBox="0 0 512 512"><path fill="currentColor" d="M256 417.1c-16.38 0-32.88-5-46.88-15.12L0 250.9V464C0 490.5 21.5 512 48 512h416c26.5 0 48-21.5 48-48V250.9l-209.1 151.1C288.9 412 272.4 417.1 256 417.1zM493.6 163C484.8 156 476.4 149.5 464 140.1V96c0-26.5-21.5-48-48-48L338.5 48c-3.125-2.25-5.875-4.25-9.125-6.5C312.6 29.13 279.3-.373 256 .002C232.8-.373 199.4 29.13 182.6 41.5c-3.25 2.25-6 4.25-9.125 6.5L96 48c-26.5 0-48 21.5-48 48v44.13C35.63 149.5 27.25 156 18.38 163C6.75 172 0 186 0 200.8v10.62l96 69.38V96h320v184.8l96-69.38V200.8C512 186 505.3 172 493.6 163zM247.7 285.1c-7.203-1.062-16.39-4.375-24.5-7.312L218.7 276.2C208.2 272.5 196.9 277.9 193.2 288.3s1.75 21.84 12.16 25.53l4.359 1.562c8.164 2.949 17.25 5.922 26.38 7.883V336c0 11.03 8.953 20 20 20s20-8.969 20-20v-12.25c23.04-4.814 39.39-18.82 43.09-40.22c7.625-44.13-33.25-55.81-57.67-62.81L255.8 219.1C230.8 211.8 231.4 208.2 232.3 203.3c1.484-8.562 17.14-10.69 31.83-8.406c5.844 .9375 12.27 2.906 18.38 5.031c10.39 3.594 21.83-1.938 25.44-12.38s-1.938-21.81-12.38-25.44c-7.506-2.584-13.7-4.24-19.48-5.482V144c0-11.03-8.953-20-20-20s-20 8.969-20 20v11.88C212.8 160.6 196.6 174.1 192.9 196.5c-7.547 43.66 33.95 55.81 51.66 61l5.953 1.719C280 267.6 280.8 270.7 279.7 276.7C278.3 285.3 262.6 287.4 247.7 285.1z"/></symbol>
        <symbol id="fa-user-group" viewBox="0 0 640 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3c-95.73 0-173.3 77.6-173.3 173.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM479.1 320h-73.85C451.2 357.7 480 414.1 480 477.3C480 490.1 476.2 501.9 470 512h138C625.7 512 640 497.6 640 479.1C640 391.6 568.4 320 479.1 320zM432 256C493.9 256 544 205.9 544 144S493.9 32 432 32c-25.11 0-48.04 8.555-66.72 22.51C376.8 76.63 384 101.4 384 128c0 35.52-11.93 68.14-31.59 94.71C372.7 243.2 400.8 256 432 256z"/></symbol>
        <symbol id="fa-user" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" /></symbol>
        <symbol id="fa-circle-plus" viewBox="0 0 512 512"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 368C269.3 368 280 357.3 280 344V280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H280V168C280 154.7 269.3 144 256 144C242.7 144 232 154.7 232 168V232H168C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H232V344C232 357.3 242.7 368 256 368z"/></symbol>
        <symbol id="fa-circle-minus" viewBox="0 0 512 512"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM168 232C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H168z"/></symbol>
        <symbol id="fa-trash" viewBox="0 0 448 512"><path fill="currentColor" d="M144 0L128 32H0V96H448V32H320L304 0H144zM416 128H32L56 512H392l24-384z"/></symbol>
        <symbol id="fa-pen-circle" viewBox="0 0 512 512"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM357.8 139.7C342.1 124.1 316.8 124.1 301.2 139.7L279.8 161.1L350.7 232.1L372.1 210.7C387.8 195 387.8 169.7 372.1 154.1L357.8 139.7zM143.5 303.8L128.5 363.1C127.1 369.4 128.7 375.2 132.7 379.2C136.7 383.1 142.4 384.7 147.9 383.4L208 368.3C213.6 366.9 218.8 364 222.9 359.9L328.1 254.7L257.1 183.8L151.9 288.1C147.8 293.1 144.9 298.2 143.5 303.8z"/></symbol>
        <symbol id="fa-pen" viewBox="0 0 512 512"><path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></symbol>
        <symbol id="fa-user-helmet-safety" viewBox="0 0 448 512"><path fill="currentColor" d="M224 320c65.25 0 118.5-49 126.4-112H97.63C105.5 271 158.8 320 224 320zM88 176h272c4.375 0 8-3.625 8-8V136c0-4.375-3.625-8-8-8H352c0-45-26.88-85.62-68.38-103.2L256 80V16c0-8.875-7.125-16-16-16h-32c-8.875 0-16 7.125-16 16V80L164.4 24.75C122.9 42.38 96 83 96 128H88c-4.375 0-8 3.625-8 8v31.1C80 172.4 83.63 176 88 176zM314.7 352H133.3C59.7 352 0 411.7 0 485.3c0 14.73 11.94 26.67 26.66 26.67H421.3c14.72 0 26.66-11.94 26.66-26.67C448 411.7 388.3 352 314.7 352z"/></symbol>
        <symbol id="fa-island-tropical" viewBox="0 0 448 512"><path fill="currentColor" d="M447.9 113.6c.5001 3.625-.4998 7.375-2.875 10.13C442.6 126.5 439.1 128 435.4 128H399.1l-16-32l-15.97 32h-101.8c11.25 48.12 28.76 143.1 18.51 231.9c52 17.12 92 62.37 99 118.1C386.1 496.2 370.5 512 351.1 512H32.08c-19.75 0-34.59-17.75-31.72-37.25C10.86 404.2 71.48 352 142.7 352h53.38C206.1 309.1 221.1 229.1 217.1 149.5l-70.1 71C140.7 225.9 131.1 224.8 127.6 218.6C102.5 182.9 107.1 131 142.1 96H111.1L95.1 64L79.1 96H44.62C40.87 96 37.37 94.5 34.99 91.75C32.62 88.1 31.67 85.25 32.17 81.62C40.29 35.38 86.99 0 143.5 0c50.25 0 92.25 28.25 106.8 67.13C270.9 45.88 301.6 32 336.5 32C393 32 439.7 67.38 447.9 113.6z"/></symbol>
               
        <symbol id="fa-chevron-up" viewBox="0 0 448 512"><path fill="currentColor" d="M201.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 173.3 54.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></symbol>
        <symbol id="fa-chevron-right" viewBox="0 0 320 512"><path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></symbol>
        <symbol id="fa-chevron-down" viewBox="0 0 448 512"><path fill="currentColor" d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></symbol>
        <symbol id="fa-chevron-left" viewBox="0 0 320 512"><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></symbol>

        <symbol id="fa-plus" viewBox="0 0 448 512"><path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/></symbol>
        <symbol id="fa-minus" viewBox="0 0 448 512"><path fill="currentColor" d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></symbol>
        <symbol id="fa-xmark" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></symbol>
        <symbol id="fa-circle-exclamation" viewBox="0 0 512 512"><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm24-384v24V264v24H232V264 152 128h48zM232 368V320h48v48H232z"/></symbol>
        <symbol id="fa-ballot-check" viewBox="0 0 448 512"><path fill="currentColor" d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 112c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112zm0 256c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V368zm144 0H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16s7.2-16 16-16zM192 128c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16zm16 112H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16s7.2-16 16-16zm-52.7-27.3c6.2 6.2 6.2 16.4 0 22.6l-48 48c-6.2 6.2-16.4 6.2-22.6 0l-24-24c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L96 249.4l36.7-36.7c6.2-6.2 16.4-6.2 22.6 0z"/></symbol>
        <symbol id="fa-check" viewBox="0 0 512 512"><path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></symbol>
        <symbol id="fa-check-edit" viewBox="0 0 640 512"><path fill="currentColor" d="M493.3 128l-22.6 22.6-256 256L192 429.3c-7.5-7.5-15.1-15.1-22.6-22.6c-42.7-42.7-85.3-85.3-128-128c-7.6-7.6-15.1-15.1-22.7-22.7c15.1-15.1 30.2-30.2 45.3-45.3c7.5 7.5 15.1 15.1 22.6 22.6c35.1 35.1 70.3 70.3 105.4 105.4c77.8-77.8 155.6-155.5 233.4-233.3c7.5-7.6 15.1-15.1 22.6-22.7c15.1 15.1 30.2 30.2 45.3 45.3zM640 294.9L569 224c-13.7 13.8-27.5 27.5-41.2 41.3c23.6 23.6 47.3 47.3 70.9 70.9c13.8-13.8 27.5-27.5 41.3-41.3zm-63.9 64l-71-71C458 335 411 382 363.9 429.1c-4 27.6-7.9 55.3-11.9 82.9c27.6-4 55.3-7.9 82.9-11.9C482 453 529 406 576.1 358.9z"/></symbol>
        <symbol id="fa-square-sliders" viewBox="0 0 448 512"><path fill="currentColor" d="M384 480c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0zm0-144c0 17.7-14.3 32-32 32l-32 0 0 16c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-16L96 368c-17.7 0-32-14.3-32-32s14.3-32 32-32l160 0 0-16c0-17.7 14.3-32 32-32s32 14.3 32 32l0 16 32 0c17.7 0 32 14.3 32 32zM352 144c17.7 0 32 14.3 32 32s-14.3 32-32 32l-160 0 0 16c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-16-32 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l32 0 0-16c0-17.7 14.3-32 32-32s32 14.3 32 32l0 16 160 0z"/></symbol>
        <symbol id="fa-file-chart-pie" viewBox="0 0 384 512"><path fill="currentColor" d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zm56 320H192V200c66.3 0 120 53.7 120 120zM160 210.4V352H301.6c-11.1 54.8-59.5 96-117.6 96c-66.3 0-120-53.7-120-120c0-58.1 41.2-106.5 96-117.6z"/></symbol>
        <symbol id="fa-bars-progress" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64C490.5 64 512 85.49 512 112V176C512 202.5 490.5 224 464 224H48C21.49 224 0 202.5 0 176V112C0 85.49 21.49 64 48 64H464zM464 112H320V176H464V112zM464 288C490.5 288 512 309.5 512 336V400C512 426.5 490.5 448 464 448H48C21.49 448 0 426.5 0 400V336C0 309.5 21.49 288 48 288H464zM464 336H192V400H464V336z"/></symbol>
        <symbol id="fa-list-timeline" viewBox="0 0 512 512"><path fill="currentColor" d="M16 96a48 48 0 1 1 96 0A48 48 0 1 1 16 96zM64 208a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm0 160a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM208 48H448v96H208L144 96l64-48zM144 256l64-48H512v96H208l-64-48zm0 160l64-48H448v96H208l-64-48z"/></symbol>

        <symbol id="fa-right-from-bracket" viewBox="0 0 512 512"><path fill="currentColor" d="M160 96h32V32H160 32 0V64 448v32H32 160h32V416H160 64L64 96l96 0zM352 416L512 256 352 96H320v96H160l0 128H320v96h32z"/></symbol>
        <symbol id="fa-right-to-bracket" viewBox="0 0 512 512"><path fill="currentColor" d="M192 96L352 256 192 416l-32 0 0-96L0 320 0 192l160 0 0-96 32 0zM352 416l96 0 0-320-96 0-32 0 0-64 32 0 128 0 32 0 0 32 0 384 0 32-32 0-128 0-32 0 0-64 32 0z"/></symbol>
        
        <symbol id="fa-distribute-h" viewBox="0 0 448 512"><path fill="currentColor" d="M0 0H48V512H0V0zM128 96H320V416H128V96zM448 0V512H400V0h48z"/></symbol>
        <symbol id="fa-clock" viewBox="0 0 512 512"><path fill="currentColor" d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256v12.8l10.7 7.1 96 64 20 13.3 26.6-39.9-20-13.3L280 243.2V120 96H232v24z"/></symbol>

        <symbol id="fa-tree-plus" viewBox="0 0 640 512"><path fill="currentColor" d="M96 48L0 48c0 32 0 64 0 96c8 0 16 0 24 0c0 37.3 0 74.7 0 112c0 53.3 0 106.7 0 160c0 8 0 16 0 24c8 0 16 0 24 0c26.7 0 53.3 0 80 0c0 8 0 16 0 24c32 0 64 0 96 0c0-32 0-64 0-96c-32 0-64 0-96 0c0 8 0 16 0 24c-18.7 0-37.3 0-56 0c0-37.3 0-74.7 0-112c18.7 0 37.3 0 56 0c0 8 0 16 0 24c32 0 64 0 96 0c0-32 0-64 0-96c-32 0-64 0-96 0c0 8 0 16 0 24c-18.7 0-37.3 0-56 0c0-29.3 0-58.7 0-88c8 0 16 0 24 0c0-32 0-64 0-96zm64 16l0 64c10.7 0 21.3 0 32 0c96 0 192 0 288 0c10.7 0 21.3 0 32 0c0-21.3 0-42.7 0-64l-32 0L192 64c-10.7 0-21.3 0-32 0zM288 224l0 64c10.7 0 21.3 0 32 0c6.4 0 12.8 0 19.2 0c13.1-25.6 32.2-47.6 55.6-64L320 224c-10.7 0-21.3 0-32 0zm0 160l0 64c10.7 0 21.3 0 32 0c6.4 0 12.8 0 19.2 0c-10-19.5-16.4-41.1-18.5-64l-.7 0c-10.7 0-21.3 0-32 0zm352-16c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM480 288l0 64c-21.3 0-42.7 0-64 0c0 10.7 0 21.3 0 32c21.3 0 42.7 0 64 0l0 64c10.7 0 21.3 0 32 0c0-21.3 0-42.7 0-64c21.3 0 42.7 0 64 0c0-10.7 0-21.3 0-32c-21.3 0-42.7 0-64 0c0-21.3 0-42.7 0-64c-10.7 0-21.3 0-32 0z"/></symbol>
        <symbol id="fa-tree-minus" viewBox="0 0 640 512"><path fill="currentColor" d="M96 48L0 48c0 32 0 64 0 96c8 0 16 0 24 0c0 37.3 0 74.7 0 112c0 53.3 0 106.7 0 160c0 8 0 16 0 24c8 0 16 0 24 0c26.7 0 53.3 0 80 0c0 8 0 16 0 24c32 0 64 0 96 0c0-32 0-64 0-96c-32 0-64 0-96 0c0 8 0 16 0 24c-18.7 0-37.3 0-56 0c0-37.3 0-74.7 0-112c18.7 0 37.3 0 56 0c0 8 0 16 0 24c32 0 64 0 96 0c0-32 0-64 0-96c-32 0-64 0-96 0c0 8 0 16 0 24c-18.7 0-37.3 0-56 0c0-29.3 0-58.7 0-88c8 0 16 0 24 0c0-32 0-64 0-96zm64 16l0 64c10.7 0 21.3 0 32 0c96 0 192 0 288 0c10.7 0 21.3 0 32 0c0-21.3 0-42.7 0-64l-32 0L192 64c-10.7 0-21.3 0-32 0zM288 224l0 64c10.7 0 21.3 0 32 0c6.4 0 12.8 0 19.2 0c13.1-25.6 32.2-47.6 55.6-64L320 224c-10.7 0-21.3 0-32 0zm0 160l0 64c10.7 0 21.3 0 32 0c6.4 0 12.8 0 19.2 0c-10-19.5-16.4-41.1-18.5-64l-.7 0c-10.7 0-21.3 0-32 0zm64-16c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm224 16l0-32c-53.3 0-106.7 0-160 0c0 10.7 0 21.3 0 32c53.3 0 106.7 0 160 0z"/></symbol>
        <symbol id="fa-arrows-rotate" viewBox="0 0 512 512"><path fill="currentColor" d="M89.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L370.3 160H320 288v64h32H448h32V192 64 32H416V64v51.2L398.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C57.2 122 39.6 150.7 28.8 181.4l60.4 21.3zM64 396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7l-60.4-21.3c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L109.6 352H160h32V288H160 32 0v32V448v32H64V448 396.9z"/></symbol>
        
        <symbol id="fa-puzzle" viewBox="0 0 512 512"><path fill="currentColor" d="M304 0H512V208H432v16c0 17.7-14.3 32-32 32s-32-14.3-32-32V208l-64 0V144h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H304V0zM0 272V64H208v64h16c17.7 0 32 14.3 32 32s-14.3 32-32 32H208v80H144V240c0-17.7-14.3-32-32-32s-32 14.3-32 32v32H0zM0 512V304H208v64H176c-17.7 0-32 14.3-32 32s14.3 32 32 32h32v80H0zM240 304h64v32c0 17.7 14.3 32 32 32s32-14.3 32-32V304h80V512H240V304z"/></symbol>
        <symbol id="fa-puzzle-piece" viewBox="0 0 512 512"><path fill="currentColor" d="M192 89.6c-19.1-8.3-32-23.8-32-41.6c0-26.5 28.7-48 64-48s64 21.5 64 48c0 17.8-12.9 33.3-32 41.6V128H384V256h38.4c8.3-19.1 23.8-32 41.6-32c26.5 0 48 28.7 48 64s-21.5 64-48 64c-17.8 0-33.3-12.9-41.6-32H384V512H256V473.6c19.1-8.3 32-23.8 32-41.6c0-26.5-28.7-48-64-48s-64 21.5-64 48c0 17.8 12.9 33.3 32 41.6V512H0V320H38.4c8.3 19.1 23.8 32 41.6 32c26.5 0 48-28.7 48-64s-21.5-64-48-64c-17.8 0-33.3 12.9-41.6 32H0V128H192V89.6z"/></symbol>
        <symbol id="fa-puzzle-piece-simple" viewBox="0 0 640 512"><path fill="currentColor" d="M256 144c0-17.8 12.9-33.3 32-41.6V64H128V224H89.6c-8.3-19.1-23.8-32-41.6-32c-26.5 0-48 28.7-48 64s21.5 64 48 64c17.8 0 33.3-12.9 41.6-32H128V448H288V409.6c-19.1-8.3-32-23.8-32-41.6c0-26.5 28.7-48 64-48s64 21.5 64 48c0 17.8-12.9 33.3-32 41.6V448H512V288h38.4c8.3 19.1 23.8 32 41.6 32c26.5 0 48-28.7 48-64s-21.5-64-48-64c-17.8 0-33.3 12.9-41.6 32H512V64H352v38.4c19.1 8.3 32 23.8 32 41.6c0 26.5-28.7 48-64 48s-64-21.5-64-48z"/></symbol>
        <symbol id="fa-memory" viewBox="0 0 576 512"><path fill="currentColor" d="M576 64H0v82.7c18.6 6.6 32 24.4 32 45.3s-13.4 38.7-32 45.3V320H576V237.3c-18.6-6.6-32-24.4-32-45.3s13.4-38.7 32-45.3V64zm0 288H0v96H80V416 400h32v16 32h96V416 400h32v16 32h96V416 400h32v16 32h96V416 400h32v16 32h80V352zM192 128v32 64 32H128V224 160 128h64zm128 32v64 32H256V224 160 128h64v32zm128-32v32 64 32H384V224 160 128h64z"/></symbol>
        
        <symbol id="fa-paper-plane" viewBox="0 0 512 512"><path fill="currentColor" d="M0 288L512 0 448 480 271.8 404.5 208 512l-48-16V416 384L384 160 133 345 0 288z"/></symbol>
        <symbol id="fa-tag" viewBox="0 0 448 512"><path fill="currentColor"  d="M0 32V256L224 480 448 256 224 32H0zm112 80a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></symbol>

        <symbol id="fa-folder" viewBox="0 0 512 512"><path fill="currentColor" d="M0 480H512V96H272L224 32H0V480z"/></symbol>
        <symbol id="fa-folder-open" viewBox="0 0 576 512"><path fill="currentColor" d="M99.4 209.7L0 408.4V32H208l64 64H480v96H128 108.2l-8.8 17.7zM0 480L128 224H576L448 480H0z"/></symbol>
        <symbol id="fa-file-word" viewBox="0 0 384 512"><path fill="currentColor" d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM107.6 240l31.7 109.3L164 257.7l4.8-17.7h18.4 9.6 18.4l4.8 17.7 24.7 91.5L276.4 240h50L271.1 430.7 266 448H248h-8H221.6l-4.8-17.7-24.8-92-24.8 92L162.4 448H144h-8H118l-5-17.3L57.7 240h50z"/></symbol>
        <symbol id="fa-file-excel" viewBox="0 0 384 512"><path fill="currentColor" d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM97.7 240h56.8L192 299.1 229.5 240h56.8L220.4 344l65.9 104H229.5L192 388.9 154.5 448H97.7l65.9-104L97.7 240z"/></symbol>
        <symbol id="fa-file-pdf" viewBox="0 0 384 512"><path fill="currentColor" d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32 16H48V368 320 240 224H64zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-80h16 24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176 160V368 240 224zm32 128h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48 16v32H336 304v32h32 16v32H336 304v48 16H272V368 304 240 224h16z"/></symbol>
        <symbol id="fa-file-zipper" viewBox="0 0 384 512"><path fill="currentColor" d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM96 32h16 32 16V64H144 112 96V32zm0 64h16 32 16v32H144 112 96V96zm0 64h16 32 16v32H144 112 96V160zM64 416V344L96 224h64l32 120v72H64zm48-80H96v32h16 32 16V336H144 112z"/></symbol>
        <symbol id="fa-file-video" viewBox="0 0 384 512"><path fill="currentColor" d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM64 256H224V416H64V256zM320 400l-64-32V304l64-32V400z"/></symbol>
        <symbol id="fa-file-image" viewBox="0 0 384 512"><path fill="currentColor" d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM96 288c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zM320 448H192 128 64V416l64-88 32.5 44.7L224 288l96 128v32z"/></symbol>
        <symbol id="fa-file" viewBox="0 0 384 512"><path fill="currentColor" d="M0 0H224V160H384V512H0V0zM384 128H256V0L384 128z"/></symbol>
        <symbol id="fa-magnifying-glass" viewBox="0 0 512 512"><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L486.6 441.4 509.3 464 464 509.3l-22.6-22.6L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"/></symbol>
        <symbol id="fa-magnifying-glass-plus" viewBox="0 0 512 512"><path fill="currentColor" d="M376 330.7c25.2-34.4 40-76.8 40-122.7C416 93.1 322.9 0 208 0S0 93.1 0 208S93.1 416 208 416c45.9 0 88.3-14.9 122.7-40L441.4 486.6 464 509.3 509.3 464l-22.6-22.6L376 330.7zM232 104v24 56h56 24v48H288 232v56 24H184V288 232H128 104V184h24 56V128 104h48z"/></symbol>
        <symbol id="fa-magnifying-glass-minus" viewBox="0 0 512 512"><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L486.6 441.4 509.3 464 464 509.3l-22.6-22.6L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM288 232h24V184H288 128 104v48h24H288z"/></symbol>
        <symbol id="fa-qrcode" viewBox="0 0 448 512"><path fill="currentColor" d="M192 32H0V224H192V32zM128 96v64H64V96h64zm64 192H0V480H192V288zm-64 64v64H64V352h64zM256 32V224H448V32H256zm64 64h64v64H320V96zm32 352v32h32V448H352zm96 0H416v32h32V448zM416 288v32H352V288H256v96 96h64V384h32v32h96V352 320 288H416z"/></symbol>
        <symbol id="fa-message" viewBox="0 0 512 512"><path fill="currentColor" d="M512 0H0V416H160v96l144-96H512V0z"/></symbol>
        <symbol id="fa-lock" viewBox="0 0 448 512"><path fill="currentColor" d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 80 0 0 320L0 512 0 192l80 0z"/></symbol>

        <symbol id="animation-waiting" style="margin: auto; background: none; display: block; shape-rendering: auto; animation-play-state: running; animation-delay: 0s;" viewBox="20 20 60 60" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" r="19" stroke-width="6" stroke="#055ffc" stroke-dasharray="29.845130209103033 29.845130209103033" fill="none" stroke-linecap="round" style="animation-play-state: running; animation-delay: 0s;">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
            </circle>
        </symbol>
    </svg>
</template>

<style>
    i {
        display: inline-block;
        width: 20px;
        text-align: center;
        margin-right: 4px;
    }

    i > svg {
        display: inline-block;
        height: 1em;
        width: 1.125em;
        vertical-align: -.125em;
        font-size: inherit;
        overflow: visible;
    }
</style>